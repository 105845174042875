.blog {
    color: #212427;
}

.hangingindent {
    padding-left: 22px ;
    text-indent: -22px ;
    font-weight: bold;
}

a {
    color: #0969da;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

[style*="--aspect-ratio"] > :first-child {
    width: 100%;
}
[style*="--aspect-ratio"] > img {
    height: auto;
}
@supports (--custom:property) {
    [style*="--aspect-ratio"] {
        position: relative;
        width: 70%;
    }

    [style*="--aspect-ratio"]::before {
        content: "";
        display: block;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
    }

    [style*="--aspect-ratio"] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}

.vertical {
    width: 40% !important;
}