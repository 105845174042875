#root {
  height: 100%;
}

.markdown-body {
  color: #212427 !important;
  background-color: white !important;
}

.markdown-body table tr {
  background-color: white !important;
  border-top: 1px solid #D8DEE4FF !important;
}

.markdown-body table tr:nth-child(2n) {
  background-color: #F6F8FAFF !important;
}

.markdown-body h2 {
  border-bottom: 1px solid #D8DEE4FF !important;
}

td {
  border: 1px solid #D0D7DEFF !important;
}

.markdown-body table th, .markdown-body table td {
  border: 1px solid #D0D7DEFF !important;
}
